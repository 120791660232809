import React from "react";
import onClickOutside from 'react-onclickoutside';
import ReactPaginate from 'react-paginate';
import {
	Dropdown as BSDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap';
import { PF_TIMESTAMP /*, DEFAULT_ERRAND_TYPE_ICONS*/ } from '../../common/v5/constants';
import { I } from '../../common/v5/config';
import SearchFilter from '../../reactcomponents/SearchFilter';
import ToggleSwitch from '../../components/v5/ToggleSwitch';
import AgentSocket from '../../common/v5/agentsocket';
import AgentWs from '../../common/v5/socket/message';
import SystemMessage from '../../components/v5/SystemMessage';
import { Message } from '../../components/v5/Message';
import {
	evtCHAT_SET_AGENT,
	evtCHAT_FINISH_SESSION,
	evtCHAT_CHAT_MESSAGE,
	evtCHAT_AGENT_PREVIEW,
	SERVICE_INTERNAL_CHAT,
} from '../../redux/constants/constants';
import {
	subscribeAgentPresence,
	unsubscribeAgentPresence,
} from '../../redux/actions/async/echat.js';
import {
	isIOS,
	getAppendAnswer,
	stripHTML,
} from '../../common/v5/helpers';
import update from 'immutability-helper';
import classNames from 'classnames';


var Linker = {
	// Regex for capturing urls in ckeditor html.
	urlRegex: /(>|^)(https?:\/\/[^ \<]+)/i,

	// Stupid simple url linkification.  This is not perfect but it
	// covers most use cases. Handles http and https links only.
	linkifyUrls: function (html) {
		var res
		, i
		;

		res = Array.prototype.slice.call(html.split(/(\&nbsp;|\s)+/g))

		for(i=0; i<res.length; i++) {
			res[i] = res[i].replace(this.urlRegex, function(match, p1, p2) {
				var url = p2
					, lastChunk = ""
				;
				while (url && url.length > 1 && (
					url[url.length-1] == '.' ||
						url[url.length-1] == ',' ||
						url[url.length-1] == '"' ||
						url[url.length-1] == ']' ||
						url[url.length-1] == ')'
				)) {
					lastChunk += url[url.length-1];
					url = url.substring(0, url.length-1);
				}
				var removeExtraTagRegex = /(<([^>]+)>)/ig;
				var linkSrc = url.replace(/"/g, '\\x22');
				var sanitizeLinkSrc = linkSrc.replace(removeExtraTagRegex, "");
				return [ p1
					, '<a href="' + sanitizeLinkSrc + '" target="_blank">' + url + '</a>'
					, lastChunk
				].join('');
			});
		}
		return res.join(' ');
	}
};

class OneItem extends React.Component {
	constructor(props) {
		super(props);
	}
	handleClick = () => {
		this.props.onClick(this.props.index);
	}
	render() {
		const p = this.props;
		if(p.isHeader) {
			return <DropdownItem header>{p.value}</DropdownItem>;
		} else {
			const active = p.selected;
			return <DropdownItem data-qa-id={"ic_dd_item_"+p.value} onClick={this.handleClick} active={active}>
					{p.value}
				</DropdownItem>;
		}
	}
}

class StaticActionDropdown extends React.Component{
	constructor(props) {
		super(props);
		this.displayName = "StaticActionDropdown";
	}
	handleClickOutside = evt => {
		this.props.onToggle(false);
	}
	handleToggle = () =>{
		this.props.onToggle(!this.props.show);
	}
	handleOnClick = index => {
		this.props.onSelectItem(this.props.id, index);
	}
	render = () => {
		const {id, show, title, data} = this.props;
		let ddItem = "";
		if(data && data.length > 0){
			ddItem = data.map((v)=>{
				return <OneItem key={v.id} value={v.value} index={v.id} onClick={this.handleOnClick} />
			});
		}
		return(
			<BSDropdown id={id} data-qa-id={"ic_act_dd_"+id} className='c3-dropdown' isOpen={show} toggle={this.handleToggle}>
				<DropdownToggle tag="span" onClick={this.handleToggle}>{title}</DropdownToggle>
				<DropdownMenu>
					{ddItem}
				</DropdownMenu>
			</BSDropdown>)
	}
}

const ActionDropdownOption = onClickOutside(StaticActionDropdown);

class ActiveConversation extends React.Component {
	constructor(props) {
		super(props);
		this.displayName = "ActiveConversation";
	}
	handleSelect = () => {
		const {chatSession, sessionIndex} = this.props
		this.props.onActivate(chatSession, sessionIndex);
	}
	render = () => {
		return (
			<li className={this.props.status}>
				<a data-qa-id={"ic-active-conv-"+this.props.nameOnly} href="#" className="user-name" title={this.props.nameTitle} onClick={this.handleSelect}>{this.props.name}</a>
				{this.props.serviceIcon}
			</li>
		)
	}
}

class InternalChatAgent extends React.Component {
	constructor(props) {
		super(props);
		this.displayName = "InternalChatAgent";
		this.state = {
			showOption: false,
			menuItems: []
		}
	}
	handleToggleMenu = (show) => {
		this.setState({showOption: show});
	}
	handleSelect = () => {
		let menuItems = [{id:0, value:"Start new chat"}],
			{id, activeTab, chatSessions} = this.props;
		if(chatSessions && Object.keys(chatSessions).length > 0) {
			let session = chatSessions[activeTab.sessionId].session;

			let isAdded = false;
			for(let i=0; i<session.agentIds.length; i++) {
				if(id == session.agentIds[i]) {
					isAdded = true;
					break;
				}
			}
			let isInvited = false;
			for(let i=0; i<session.invitedAgentIds.length; i++) {
				if(id == session.invitedAgentIds[i]) {
					isInvited = true;
					break;
				}
			}
			if (isAdded || isInvited) {
				if (isInvited) {
					menuItems.push({id:1, value:"Add to chat"});
				}
				if(session.agentIds.length >= 2){
					menuItems.push({id:2, value:"Remove from chat"});
				}
			} else {
				menuItems.push({id:1, value:"Add to chat"});
			}
		}
		this.setState({
			showOption: true,
			menuItems: menuItems
		});
	}
	handleSelectItem = (id, opt) => {
		this.setState({showOption: false});
		this.props.onHandleChatAction(id, opt);
	}
	handleFavourite = () => {
		this.props.onHandleSetFavourite(this.props.id, !this.props.favourite, this.props.isSocial);
	}
	render = () => {
		const {id, name, status, favourite, serviceIcon} = this.props;
		let isFavourite = favourite ? "fa fa-star" : "far fa-star";

		return (
			<li className={status}>
				<a className="user-name" data-qa-id={"ic-select-agent-"+name} href="#" title="" onClick={this.handleSelect}>{name}</a>
				{serviceIcon}
				<a data-qa-id={"ic-add-remove-fav-"+name} href="#" title="" onClick={this.handleFavourite}> <i className={isFavourite}></i></a>
				<ActionDropdownOption
					id={id}
					show={this.state.showOption}
					data={this.state.menuItems}
					onToggle={this.handleToggleMenu}
					onSelectItem={this.handleSelectItem}
				/>
			</li>
		)
	}
}

class InternalChatConversation extends React.Component {
	constructor(props) {
		super(props);
		this.displayName = "InternalChatConversation";
		this.previewTimeout = null;
		this.state = {
			isScrollToTop: false,
			isSendingMsg: false,
			chatText: "",
			showRightClickOptions: false
		}
	}
	componentDidMount(){
		this.props.activateFirstTab();
		this.scrollMessageListToBottom();
		// this.sessionHeader.addEventListener('contextmenu', this.handleRightClick);
	}
	componentDidUpdate(){
		if(!this.state.isScrollToTop){
			this.scrollMessageListToBottom();
		}
		let {sessionId} = this.props.activeTab;
		if(sessionId == NEW_AGENT_CHAT) return;
		if(typeof sessionId !== 'undefined'){
			if(!this.props.chatSessions[sessionId]){
				this.props.activateFirstTab();
			}
		} else {
			this.props.activateFirstTab();
		}
		if(this.props.active && this.props.chatSession.isDirty && this.props.activateChat){
			var self = this;
			setTimeout(function(){
				self.props.onActivate(self.props.sessionId);
			});
		}
	}
	componentWillUnmount(){
		// this.sessionHeader.removeEventListener('contextmenu', this.handleRightClick);
	}
	handleRightClick = (e) => {
		e.preventDefault();
		this.setState({
			showRightClickOptions: true
		});
	}
	handleSelectRightClickItem = (id, opt) => {
		this.setState({showRightClickOptions: false},
			() => {
				if(opt === 1){
					this.closeSession();
				}
			}
		);
	}
	handleToggleRightMenu = () => {
		this.setState({
			showRightClickOptions: false
		});
	}
	handleScroll = () => {
		if (!this.msgRef) {
			return
		}
		var timer;
		clearTimeout(timer);
		timer = setTimeout( function(){
			let buffer = Math.round(this.msgRef.offsetHeight * 0.05); // add 5% buffer
			if( this.msgRef.scrollTop >= (this.msgRef.scrollHeight - this.msgRef.offsetHeight - buffer)) {
				this.props.onUpdateNewMessageStatus(this.props.sessionId, false);
				this.setState({isScrollToTop : false});
			}else{
				this.setState({isScrollToTop : true});
			}
		}.bind(this), 500);
	}

	handleIosCloseChat = (e) => {
		this.handleRightClick(e);
	}
	scrollMessageListToBottom = () => {
		if (!this.msgRef) {
			return
		}
		this.msgRef.scrollTop = this.msgRef.scrollHeight;
	}
	hideNewMessageIndicator = () => {
		this.scrollMessageListToBottom();
		this.props.onUpdateNewMessageStatus(this.props.sessionId, false);
		this.setState({isScrollToTop: false});
	}
	stripLeadingAndTrailingBlock = (msg) => {
		var emptyLine = '<div>\xa0</div>';
		while(msg.indexOf(emptyLine) == 0){
			msg = msg.replace(emptyLine,'');
			msg = msg.trim();
		}
		msg = msg.replace(/\n/g,'');
		while(msg.lastIndexOf(emptyLine) != -1){
			if((msg.lastIndexOf(emptyLine)+ emptyLine.length) ==
				msg.length){
				msg = msg.slice(0, -emptyLine.length);
			} else break
		}
		msg = msg.replace(/<div[^>]*>/g,'');
		msg = msg.replace(/<\/div>/g,'<br>');
		var lastSubString = msg.substring(msg.length-'<br>'.length,
			msg.length);
		if(lastSubString == '<br>'){
			msg = msg.substring(0, msg.length-'<br>'.length);
		}
		return msg;
	}
	canSend = () => {
		if(this.state.chatText){
			let a = this.state.chatText;
			// a = a.replace(ckePlaceholderText,'');
			a = a.replace(/\s/g,'');
			a = a.replace(/(\r\n|\n|\r)/gm,'');
			a = a.replace(/[\u21B5|\u000A]/g, ""); //remove this guy '↵'
			a = a.replace(new RegExp('<div></div>','g'),'');
			return a != "";
		}
		return false;
	}
	answerInvite = (sessionId, answer) => {
		this.props.onUpdateAnswerInvite({
			session: sessionId,
			accept: answer
		});
	}
	closeSession = () => {
		let {sessionId} = this.props;
		//probably doesn't used
		if(typeof sessionId !== 'undefined'){
			let NewSocket = AgentSocket;
			if(features["chat.enable-new-chat"]) {
				NewSocket = AgentWs;
			}
			NewSocket.SendEvent(evtCHAT_FINISH_SESSION, {
				sessionId: sessionId
			}, () => {
				this.props.activateFirstTab();
			});
		}
	}
	toggleSessionWindow = () => {
		this.props.onToggleMaximizeSession( !this.props.maximizeChatSession)
	}
	emitMessage = (o) => {
		let um, message, messageElement, div;
		if (o.um.length == 0) {
			return;
		}
		um = o.um[0];
		message = um.text;
		AgentSocket.SendEvent(evtCHAT_CHAT_MESSAGE, {
			id: um.id,
			sessionId: o.sessionId,
			message: message,
			mcount: o.mCount,
			isInternalChat: o.isInternalChat,
			internalChatData: o.internalChatData,
		}, (ack) => {
			var onError = (errorMsg) => {
				this.props.onUpdateMessage({
					id: um.id,
					errandId: o.errandId,
					sessionId: o.sessionId,
					okToRemove: false,
					alreadySeen: false,
					errorNote: errorMsg,
					msg: {}
				});
			};
			var okToRemove = false
				, errorNote = ''
				, alreadySeen = false
				;
			if (!ack) {
				// log('chat.emit[chat message]: ack is null');
				onError("message not sent");
				return;
			}
			// ack = {error: null, id: 1005, sent: 1521691171, sentHuman: "11:59", umid: "a-3-1521691171746-1"}
			if (!ack) {
				// log('chat.emit[chat message]: decoded ack is null');
				onError("message not sent");
				return;
			}
			// log("ack:",ack);
			if (ack.error) {
				switch (ack.error) {
					case 'ERR_NIL_CHAT':
					case 'ERR_TRY_AGAIN':
						setTimeout(function(){
							this.props.onUnsentMessages({callback: this.emitMessage});
						}, 4000);
						return;
					case 'ERR_INVALID_MESSAGE_ID':
						// This should not happen
						okToRemove = true;
						errorNote = I('Message could not be sent (invalid umid)');
						break;
					case 'ERR_ALREADY_SEEN':
						alreadySeen = true;
						okToRemove = true;
						break;
					case 'ERR_INVALID_CHAT_ID':
						okToRemove = true;
						errorNote = I('Message could not be sent (invalid chat id)');
						break;
					case 'ERR_INVALID_CHAT_STATE':
						okToRemove = true;
						errorNote = I('Message could not be sent (invalid chat session)');
						break;
					case 'ERR_WAITING_TO_TAG':
						okToRemove= true;
						errorNote = I('This chat session has ended.');
						break;
					case 'ERR_EXPIRED':
						okToRemove= true;
						errorNote = I('This chat session has ended.');
						break;
					default:
						throw new Error("unhandled ack.error '" + ack.error + "'");
				}
			} else {
				okToRemove = true;
			}
			this.props.onUpdateMessage({
				id: um.id,
				errandId: o.errandId,
				sessionId: o.sessionId,
				okToRemove: okToRemove,
				alreadySeen: alreadySeen,
				errorNote: errorNote,
				msg: ack
			});
			// Keep sending until there are no more messages in the send queue
			this.props.onUnsentMessages({callback: this.emitMessage});
		});
	}
	sendMessage = () => {
		if(this.state.isSendingMsg){
			return;
		}
		if(this.canSend()){
			let p = this.props;
			let sessionId = p.activeTab.sessionId;
			let currentSession = {};
			if(typeof sessionId !== 'undefined'){
				if(p.chatSessions[sessionId] != null){
					currentSession = p.chatSessions[sessionId].session;
					if(p.chatSessions[sessionId].session.dead == true){
						this.setState({chatText: ""});
						return;
					}
				}
			}
			this.setState({isSendingMsg:true});
			let msgToSend = this.stripLeadingAndTrailingBlock(this.state.chatText);
			var doSend = (msg)=> {
				let sendMsg = (m) => {
					this.props.onQueueSend({
						errandId: p.activeTab.errandId,
						// sender: p.activeTab.agent,
						sessionId: p.activeTab.sessionId,
						preview: m,
						callback: this.emitMessage,
						isInternalChat: currentSession.isInternalChat,
						internalChatData: currentSession.internalChatData,
					});
					this.setState({isSendingMsg:false});
				}
				sendMsg(msg);
				return;
			};
			doSend(msgToSend);
		}
	}
	sendAgentPreview = () => {
		var s = this.state.chatText;
		if(s != ""){
			var msgToSend = this.stripLeadingAndTrailingBlock(s);
			var sendIt = true;
			if((msgToSend.charAt(0) == '\xa0') &&
				(msgToSend.length <= 6)){
				sendIt = false;
			}
			if(sendIt){
				var msgLength = 0;
				if((msgToSend.length == 1) &&
					(msgToSend.charCodeAt(0) == 32)){
					msgLength = 0;
				} else{
					msgLength = msgToSend.length;
				}
				AgentSocket.SendEvent(evtCHAT_AGENT_PREVIEW, {
					sessionId: this.props.activeTab.sessionId,
					messageLen: msgLength
				})
			}
		}
	}
	handleChangeChatText = (e) => {
		this.setState({chatText: e.target.value});
	}
	handleChatTextKeydown = (e) => {
		clearTimeout(this.previewTimeout);
		if(this.state.isSendingMsg){
			return;
		}
		if(e.keyCode == 13) {
			e.preventDefault();
			window.setTimeout(function() {
				this.sendMessage()
				this.setState({chatText: ""});
			}.bind(this),0);
			// e.cancel(); // avoid newline to be sent out
			return;
		}
		this.previewTimeout = setTimeout(this.sendAgentPreview, 400);
	}
	handleActionClick = (action, mid, msg) => {
		switch(action){
			case 'clone':
				// copying/append message into current answer box
				let strAnswer = getAppendAnswer(this.props.errandInputs.update_answer, msg);
				this.props.onCopyErrandMessage(strAnswer,  stripHTML(strAnswer));
				break;
			case 'note':
				this.props.onCreateErrandNote(msg);
				break;
		}
	}
	renderStatusBox = () => {
		let newMessage;
		if(typeof this.props.chatSession !== 'undefined' && this.props.chatSession) {
			newMessage = this.props.chatSession.newMessage;
		}
		let statusBox;
		if (newMessage && this.state.isScrollToTop) {
			statusBox = <div className="status-box" onClick={this.hideNewMessageIndicator}>
				{I("New message available")}
			</div>
		}
		return statusBox;
	}
	renderMessages = () => {
		let msgs = [], i=0;
		let sessionId = this.props.chatSession.sessionId;
		$.each(this.props.chatSession.chat, function(index, msg) {
			let props = {
				type: "INTERNALCHAT",
				status: I("Sent"),
				parseFormat: PF_TIMESTAMP,
				time: msg.sent,
				html: msg.text,
				notErrand: true,
				photo: "",
				channelId: this.props.channelId,
				channelIcon: this.props.channelIcon
			};
			if(msg.aid === 0) {
				props.sender = "SYSTEM";
			} else {
				props.sender = "AGENT";
				// props.name = chat.errand.data.agent;
				if(this.props.getAgent(msg.aid)){
					props.photo = this.props.getAgent(msg.aid).avatar;
				}
				props.isChat = true;
				props.isAgent = (msg.aid === initialChatData.agentID);
				props.onActionClick = this.handleActionClick;
				props.showSize = 0;
				props.clone = true;
				props.note = true;
				props.showAll = false;
				props.name = msg.agent;
			}
			i++;
			if(props.sender == "SYSTEM") {
				msgs.push(
					<div key={"sys-"+sessionId+"-"+i} className={'message-system'}>
						<SystemMessage key={"msg-"+sessionId+"-"+i} json={msg.text} getAgent={this.props.getAgent}/>
					</div>
				);
			} else {
				msgs.push(<Message key={"msg-"+sessionId+"-"+i} {...props}/>);
			}
		}.bind(this));

		$.each(this.props.chatSession.unsentMessagesArray, function(index, msg) {
			let props = {
				type: "INTERNALCHAT",
				status: msg.error ? I("Send failed: {ERROR}").replace("{ERROR}", msg.error) : I("Sending..."),
				html: msg.text,
				sender: "AGENT",
				// name: chat.errand.data.agent,
				photo: "",
				isAgent: true,
				notErrand: true,
				error: msg.error,
				channelId: this.props.channelId,
				channelIcon: this.props.channelIcon
			};
			if(this.props.getAgent(msg.aid)){
				props.photo = this.props.getAgent(msg.aid).avatar;
			}
			i++;
			msgs.push(<Message key={"msg-"+sessionId+"-"+i} {...props}/>);
		}.bind(this));

		return msgs;
	}
	render() {
		const p = this.props;
		let {sessionId, active, conversationTitle, chatSession} = this.props,
			isInvited = false,
			isDead = false,
			acceptInvite = () => { this.answerInvite(sessionId, true); },
			rejectInvite = () => { this.answerInvite(sessionId, false); },
			closeButton,
			minimizeButton,
			buttons,
			messageBox,
			iOSDevice = isIOS();
		let maximizeChatSession = p.maximizeChatSession, displayStyle = {display: "block"}, iconMaxMin = "fa fa-minus";
		if(!maximizeChatSession){
			displayStyle = {display: "none"};
			iconMaxMin = "fa fa-plus";
		}
		let rightClickMenuItems = [{id: 1, value: I("Close chat")}];

		if (chatSession) {
			for(let agentId of chatSession.invitedAgentIds) {
				if (agentId == initialChatData.agentID && chatSession.ownerId != initialChatData.agentID && chatSession.isInvited) {
					isInvited = true;
					break;
				}
			}
			isDead = chatSession.dead;
		}
		if (isInvited) {
			if (isDead) {
				buttons = <div className={"button-row" + (active?"":" hidden")}>
					<button data-qa-id={"ic_reject_invite_btn"} className="btn-blue" type="button" onClick={rejectInvite}>{I("Close")}</button>
				</div>
			} else {
				buttons = <div className={"button-row" + (active?"":" hidden")}>
					<button data-qa-id={"ic_join_btn"} className={"btn-blue"} type="button" onClick={acceptInvite}>{I("Join")}</button>
					<button data-qa-id={"ic_reject_btn"} className={"btn-blue"} type="button" onClick={rejectInvite}>{I("Reject")}</button>
				</div>
			}
		} else {
			closeButton = <button data-qa-id={"ic_close_btn"} className="close" onClick={this.closeSession}><i className="icon-times"></i></button>
			minimizeButton = <button data-qa-id="ic_minMax_btn" className="close" onClick={this.toggleSessionWindow}><i className={iconMaxMin}></i></button>
			messageBox =
				<div className={"internal-chat-conversation-message" + (active?"":" hidden")}>
					<textarea data-qa-id={"ic_chat_msg_input"} name="chat-conversation-message" placeholder="Write a message…" onChange={this.handleChangeChatText} value={this.state.chatText} onKeyDown={this.handleChatTextKeydown}></textarea>
				</div>
		}
		return(
			<div className="internal-chat-conversation">
				{/* //--old internal-conversation-header--
				<div className={"internal-chat-conversation-header" + (active?"":" hidden")} data-status={conversationTitle.status} title={conversationTitle.hoverText} ref={elem => this.sessionHeader = elem}>
					<span className="user-name" onClick={iOSDevice ? this.handleIosCloseChat : null}>{conversationTitle.text}</span>
					{minimizeButton}
					<ActionDropdownOption
						id={"internalChat-RightClick"}
						show={this.state.showRightClickOptions}
						data={rightClickMenuItems}
						onToggle={this.handleToggleRightMenu}
						onSelectItem={this.handleSelectRightClickItem}
					/>
				</div> */}
				<div ref={e => this.msgRef = e} className={"internal-chat-conversation-messages" + (active?"":" hidden")} style={displayStyle} onScroll={this.handleScroll} data-custom-scrollbar>
					{this.renderMessages()}
					{this.renderStatusBox()}
				</div>
				{buttons}
				{messageBox}
			</div>
		)
	}
}
var NEW_AGENT_CHAT = -42;

class ICConversationHeader extends React.Component {
	constructor(props) {
		super(props);
	}

	handlePageClick = (e) => {
		let selectedChatIndex = e.selected;
		const {chatSessions, onActivateSession} = this.props;
		let chatIndex;
		$.each(chatSessions, function(id, chat) {
			let session = chat.session;
			chatIndex = Object.keys(chatSessions).indexOf(id);
			if(chatIndex === selectedChatIndex){
				onActivateSession(session, selectedChatIndex);
			}
		});

	}

	render() {
		const {
			active
			, status
			, label
			, agents
			, ontoggleClosePanel
			, onCloseChat
			, currentPage
			, totalSession
			, chatSessions
			, chatSessionList
			, onActivateSession
			, maximizeChatSession
			, channel
			, serviceIcon
		} = this.props;
		const prevIcon = <i className="icon-chevron-left"></i>;
		const nextIcon = <i className="icon-chevron-right"></i>;

		return (
			<div className={classNames("internal-chat-conversation-header v5",{"hidden":!active}, channel)}>
				<div className="details-container" title={label}>
					{serviceIcon}
					<span className="user-status" data-status={status}></span>
					<span className="user-name">{agents}</span>
				</div>
				<div className="options-container">
					<div className="ic-pagination-wrapper">
						<ReactPaginate
							previousLabel={prevIcon}
							nextLabel={nextIcon}
							// initialPage={currentPage}
							pageCount={totalSession}
							onPageChange={this.handlePageClick}
							containerClassName={"pagination"}
							pageClassName={"page"}
							previousLinkClassName={"pagination__link"}
							nextLinkClassName={"pagination_link"}
							disabledClassName={"disabled"}
							activeClassName={"active"}
							forcePage={currentPage}
						/>
					</div>
					<button data-qa-id="ic_Close_btn" className="btn-close" onClick={onCloseChat}>
						<i className="icon-times"></i>
					</button>
					<button data-qa-id="ic_minMax_btn" className="btn-minimize" onClick={ontoggleClosePanel}>
						<i className={maximizeChatSession?"icon-chevron-down":"icon-chevron-up"}></i>
					</button>
				</div>
			</div>
		)
	}
}

class InternalChat extends React.Component {
	constructor(props) {
		super(props);
		this.displayName = "InternalChat";
		this.state = {
			filteredAgents: [],
			activeTab: {},
			// activeTabIndex: 0,
			maximizeChatSession: false
		};
	}
	fetchAgentList = () => {
		if(this.props.connectStatus){
			this.props.onFetchAgent({
				area: 0,
				groupByArea: false,
				includeOffline: true,
				sessionId: 0
			});
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps.connectStatus != this.props.connectStatus) {
			this.fetchAgentList();
		}
		if(prevProps.activateChat != this.props.activateChat) {
			if(this.props.activateChat) {
				// Get updated agent status upon open
				this.fetchAgentList();
				subscribeAgentPresence();
			} else {
				unsubscribeAgentPresence();
			}
		}
		if(prevProps.chatSessionList != this.props.chatSessionList) {
			let list = this.props.chatSessionList;
			if(list) {
				let currentPage = list.indexOf(this.state.activeTab.sessionId);
				this.setState({
					activeTabIndex: currentPage
				})
			}
		}
	}
	handleToggleChat = (activate) => {
		this.props.onAcceptChat({acceptChat: activate});
	}
	toggleChatWindow = () => {
		if (!this.props.activateChat) {
			if (Object.keys(this.state.activeTab).length > 0) {
				this.props.onResetNewMessageCount(this.state.activeTab.sessionId);
			}
			this.setState({maximizeChatSession: true});
		}
		this.props.onToggleChatWindow();
	}
	toggleShowConversation = () => {
		this.props.onShowConversations(!this.props.ui.showConversations);
	}
	toggleShowFavourite = () => {
		this.props.onShowFavourites(!this.props.ui.showFavourites);
	}
	toggleShowAll = () => {
		this.props.onShowAll(!this.props.ui.showAll);
	}
	handleFilteredList = (agents) => {
		this.setState({filteredAgents: agents})
	}
	handleChatAction = (id, index) => {
		let finalist = {}, ids = [];
		let chatSessions = this.props.chatSessions;
		let intChatService = SERVICE_INTERNAL_CHAT;
		let intChatservieAccId = 0;
		let isSocialMedia = false;
		if(typeof this.props.agentList === 'object'){
			let serviceAgent = this.props.agentList[id];
			if(typeof serviceAgent !== 'undefined' && serviceAgent !== null ){
				if(serviceAgent.socialMedia){
					isSocialMedia = true;
					intChatService = serviceAgent.media.mediaId;
					intChatservieAccId = serviceAgent.media.mediaContact.accountId;
				}
			}
		}
		finalist[initialChatData.agentID] = initialChatData.agentID;
		if (index == 0) { // start new chat
			finalist[id] = id;
			this.setState({maximizeChatSession: true});
		} else {
			if(chatSessions && Object.keys(chatSessions).length > 0) {
				let sessionId = this.state.activeTab.sessionId,
					agentIds = chatSessions[sessionId].session.agentIds.concat(chatSessions[sessionId].session.invitedAgentIds);
				$.each(agentIds, function(i, id) {
					finalist[id] = id;
				});
			}
			if (index == 1) { // add to chat
				finalist[id] = id;
			}
			if (index == 2) { // remove from chat
				delete finalist[id];
			}
		}
		$.each(finalist, function(i, id) {
			ids.push(finalist[id]);
		});
		ids.sort();
		AgentSocket.SendEvent(evtCHAT_SET_AGENT, {
			sessionId: (index==0)? NEW_AGENT_CHAT : this.state.activeTab.sessionId,
			agentIds: ids,
			service: intChatService,
			serviceAccountId: intChatservieAccId,
			isSocialMediaChat: isSocialMedia,
		}, (ack) => {
			console.log('Send Event: Acked =', ack);
		});
	}
	handleSetFavourite = (id, add, isSocial) => {
		this.props.onUpdateFavourite({id: id, favourite: add, isSocial: isSocial});
	}
	//ToFix: Maybe rename, cause it seems to activate latest Tab instead of first?
	activateFirstTab = () => {
		const {chatSessions} = this.props;
		let activeTab;
		if(Object.keys(chatSessions).length > 0){
			$.each(chatSessions, function(index, chatSession) {
				let session = chatSession.session;
				if(session && session.sessionId>0){
					activeTab = session;
				}
			});
		}
		this.setState({activeTab: activeTab});
	}

	goToFirstTab = () => {
		let activeTab;
		const {chatSessions} = this.props;
		if(Object.keys(chatSessions).length > 0){
			let session = chatSessions[Object.keys(chatSessions)[0]].session
			activeTab = session
		}
		this.setState({activeTab: activeTab});
	}

	handleActivateSession = (session, idx) => {
		if(this.state.activeTab.sessionId != session.sessionId) {
			this.setState({activeTab: session, maximizeChatSession: true});
			const {chatSessionList} = this.props
			let that = this;
			if(typeof idx !== undefined){
				$.each(chatSessionList, function (index, chatSession) {
					if(index === idx){
						that.setState({activeTabIndex: idx});
					}
				})
			}
		}
		this.props.onResetNewMessageCount(session.sessionId);
	}
	handleMaximizeChatSession = (toggle) => {
		this.setState({maximizeChatSession: toggle});
	}
	toggleClosePanel = () => {
		this.setState({maximizeChatSession: !this.state.maximizeChatSession});
	}
	agentChatName = (agent) => {
		if (!agent) {
			return "N/A";
		}
		if (agent.chatName) {
			return agent.chatName;
		}
		return agent.userName;
	}
	getAgent = (id) => {
		let agent = {};
		if (Object.keys(this.props.agentPresence).length > 0) {
			agent = this.props.agentPresence[id];
		}
		return agent;
	}
	getConversationTitle = (session) => {
		let isOwner = (session.ownerId == initialChatData.agentID),
			isInvited = !isOwner && session.isInvited,
			text = "",
			hoverText = "",
			status = 'offline',
			agents;

		if (Object.keys(this.props.agentPresence).length > 0) {
			let agentIds = session.agentIds.concat(session.invitedAgentIds),
				agentList = [];
			$.each(agentIds, function(index, agentId) {
				if (agentId != initialChatData.agentID) {
					let agent = this.getAgent(agentId);
					if (agent && Object.keys(agent).length > 0) {
						let agentStatus = this.getAgentStatus(agent)
						agentList.push(this.agentChatName(agent));
						if (agentStatus == 'online') {
							status = agentStatus;
						}
					}
				}
			}.bind(this));

			agents = (agentList.length > 0) ? agentList.join(", ") : I("Session closed");
			if (isOwner) {
				text = agents;
				hoverText = I("You started this chat");
			} else if (isInvited) {
				text = I("{SESSION_ID} (invitation)").replace("{SESSION_ID}", agents);
				hoverText = I("You are invited to chat");
			} else {
				text = "(" + agents + ")";
				hoverText = I("You were invited to this chat");
			}

			// Show new message count if any and only for non-active session
			if (session.newMessageCount > 0 && this.state.activeTab.sessionId != session.sessionId) {
				text += " ("+session.newMessageCount+")";
			}
		}
		return {
			hoverText: hoverText + ": " + agents,
			text: text,
			status
		};
	}
	getServiceIcon = (id) => {
		let defaultIcon = DEFAULT_ERRAND_TYPE_ICONS[3];
		let iconBlock = <i className={defaultIcon}></i>;

		let icon = DEFAULT_ERRAND_TYPE_ICONS[id];
		if(typeof icon !== 'undefined'){
			iconBlock = <i className={icon}></i>;
		}
		return iconBlock
	}
	getAgentStatus = (agent) => {
		return agent && agent.online && agent.acceptInternalChat ? "online" : "offline";
	}
	renderHeader = () => {
		let newMessageCount = (this.props.newMessageCount > 0) ?
			<li data-qa-id="ic_count" className="count" onClick={() => {this.toggleChatWindow()}}>{this.props.newMessageCount}</li> : null;
		return(
			<div className="internal-chat-header">
				<ToggleSwitch id={"internal-chat-styler"} name={"internal-chat"} label={""} handleToggle={this.handleToggleChat} listWrap={false} checked={this.props.acceptInternalChat}/>
				<div className="toggle-im" data-qa-id={"toggle-internal-chat"} onClick={() => {this.toggleChatWindow()}}>
					<span>{I("Internal Chat")}</span>
					<ul className="internal-chat-header-nav">
						{newMessageCount}
						<li data-qa-id="ic_expand_arrow"><i className="icon-chevron-up"></i></li>
					</ul>
				</div>
			</div>
		);
	}
	//todo: add confirmation popup before clossing chat
	closeSession = () => {
		let sessionId = this.state.activeTab.sessionId;
		//probable doesn't used
		if(typeof sessionId !== 'undefined'){
			let NewSocket = AgentSocket;
			if(features["chat.enable-new-chat"]) {
				NewSocket = AgentWs;
			}
			NewSocket.SendEvent(evtCHAT_FINISH_SESSION, {
				sessionId: sessionId
			}, () => {
				this.goToFirstTab();
			});
		}
	}
	getChannel = (id) => {
		let channel = 'default', channelList = this.props.channelList
		$.each(channelList, function(i, v){
			if(id == i){
				channel = channelList[i].channel;
			}
		});
		return channel;
	}
	getChannelIcon = (id) => {
		let icon = "", channelList = this.props.channelList
		$.each(channelList, function(i, v){
			if(id == i){
				icon = channelList[i].icon;
			}
		});
		return icon;
	}
	render() {
		const p = this.props;
		let numOfSessions = Object.keys(p.chatSessions).length;
		let internalChatClass = "internal-chat";
		let internalChatUsersClass = "internal-chat-users";
		if(p.activateChat){
			internalChatClass += ' opened';
			if(this.state.maximizeChatSession){
				internalChatClass += ' blocked';
			}
			if(numOfSessions){
				internalChatClass += ' active';
			}
		}
		if(p.videoCallFullScreen) {
			internalChatClass += ' hidden';
		}
		if(this.state.maximizeChatSession){
			internalChatUsersClass += ' blocked';
		}
		if(!p.connectStatus) {
			return(
				<div className={internalChatClass}>
					{this.renderHeader()}
					<div className="internal-chat-content">
						<div className="internal-chat-conversation">
							<div className="internal-chat-conversation-messages" data-custom-scrollbar>
								<span>{I("Connecting...")}</span>
							</div>
						</div>
					</div>
				</div>
			);
		}
		let agents = [];
		let channelTypes = Object.assign({}, p.channelList);

		if(p.ui.showAll) {
			if(this.state.filteredAgents.length > 0) {
				// Filtered search agents
				$.each(this.state.filteredAgents, function(k,v) {
					let agent = p.agentList[v.id];
					(agent.id != initialChatData.agentID) && agents.push(
						<InternalChatAgent
							key={agent.id}
							id={agent.id}
							name={this.agentChatName(agent)}
							status={this.getAgentStatus(agent)}
							favourite={agent.favourite}
							activeTab={this.state.activeTab}
							chatSessions={p.chatSessions}
							onHandleChatAction={this.handleChatAction}
							onHandleSetFavourite={this.handleSetFavourite}
							isSocial={agent.socialMedia}
						/>
					);
				}.bind(this));
			} else if(p.agentList) {
				// All agents
				$.each(p.agentList, function(id, agent) {
					let serviceIcon = this.getServiceIcon(agent.media.mediaId);
					if(agent.id != initialChatData.agentID){
						$.each(channelTypes,function(id, val){
							if(typeof agent.media !== undefined && agent.media.mediaId === parseInt(id)){
								channelTypes = update(channelTypes, {
									[id]:{list:{$push: [
										<InternalChatAgent
											key={agent.id}
											id={agent.id}
											name={this.agentChatName(agent)}
											status={this.getAgentStatus(agent)}
											favourite={agent.favourite}
											activeTab={this.state.activeTab}
											chatSessions={p.chatSessions}
											onHandleChatAction={this.handleChatAction}
											onHandleSetFavourite={this.handleSetFavourite}
											serviceIcon={<i className={channelTypes[id].icon}></i>}
											isSocial={agent.socialMedia}
										/>
									]}}
								});
							}
						}.bind(this));
						// agents.push(
						// 	<InternalChatAgent
						// 		key={agent.id}
						// 		id={agent.id}
						// 		name={this.agentChatName(agent)}
						// 		status={this.getAgentStatus(agent)}
						// 		favourite={agent.favourite}
						// 		activeTab={this.state.activeTab}
						// 		chatSessions={p.chatSessions}
						// 		onHandleChatAction={this.handleChatAction}
						// 		onHandleSetFavourite={this.handleSetFavourite}
						// 		serviceIcon={serviceIcon}
						// 	/>
						// );
					}
				}.bind(this));
			}
		}
		$.each(channelTypes,function(i,v){
			let lists = channelTypes[i].list;
			if(lists.length > 0){
				agents.push(<div key={"channel "+i} className="channel-list-container">
					<div className="channel-list-header">
						<i className={channelTypes[i].icon}></i>
						<div className="title">{channelTypes[i].name}</div>
					</div>
					<div className="list-container">{lists}</div>
				</div>);
			}
		});

		// Favourite list
		let favourites = [];
		if(p.ui.showFavourites && p.favourites) {
			$.each(p.favourites, function(id, agent) {
				let serviceIcon = this.getServiceIcon(agent.media.mediaId)
				favourites.push(
					<InternalChatAgent
						key={agent.id}
						id={agent.id}
						name={this.agentChatName(agent)}
						status={this.getAgentStatus(agent)}
						favourite={agent.favourite}
						activeTab={this.state.activeTab}
						chatSessions={p.chatSessions}
						onHandleChatAction={this.handleChatAction}
						onHandleSetFavourite={this.handleSetFavourite}
						serviceIcon={serviceIcon}
						isSocial={agent.socialMedia}
					/>);
			}.bind(this));
		}
		// Active conversations
		let conversations = [], tabs = [];
		let uIHeaderBar;
		if(Object.keys(p.chatSessions).length > 0){
			let okToLoad = true;
			$.each(p.chatSessions, function(index, chatSession) {
				if(!chatSession.session) {
					okToLoad = false;
				}
			});
			if(okToLoad){
				$.each(p.chatSessions, function(index, chatSession) {
					if(chatSession.session){
						let agentIds = chatSession.session.agentIds.concat(chatSession.session.invitedAgentIds);
						let title = this.getConversationTitle(chatSession.session);
						let sessionIndex = Object.keys(p.chatSessions).indexOf(index);
						let active = (this.state.activeTab.sessionId == chatSession.session.sessionId)
						let mediaType = chatSession.session.internalChatData.mediaType
						let serviceIcon = this.getServiceIcon(mediaType);
						let channelIcon = this.getChannelIcon(mediaType);
						conversations.push(
							<ActiveConversation
								key={chatSession.session.sessionId}
								chatSession={chatSession.session}
								name={title.text}
								nameOnly={chatSession.session.agent}
								status={title.status}
								nameTitle={title.hoverText}
								active={this.state.activeTab.sessionId == chatSession.session.sessionId}
								isOwner={chatSession.session.ownerId == initialChatData.agentID}
								isInvited={chatSession.session.isInvited}
								onActivate={this.handleActivateSession}
								sessionIndex={sessionIndex}
								mediaType={mediaType}
								serviceIcon={serviceIcon}
						/>);
						tabs.push(
							<InternalChatConversation
								key={chatSession.session.sessionId}
								sessionId={chatSession.session.sessionId}
								active={this.state.activeTab.sessionId == chatSession.session.sessionId}
								onToggleMaximizeSession={this.handleMaximizeChatSession}
								maximizeChatSession={this.state.maximizeChatSession}
								chatSession={chatSession.session}
								chatNotification={chatSession.notification}
								chatSessions={p.chatSessions}
								activeTab={this.state.activeTab}
								activateFirstTab={this.activateFirstTab}
								activateChat={p.activateChat}
								conversationTitle={title}
								getAgent={this.getAgent}
								onActivate={p.onActivate}
								onUpdateAnswerInvite={p.onUpdateAnswerInvite}
								onQueueSend={p.onQueueSend}
								onUnsentMessages={p.onUnsentMessages}
								onUpdateMessage={p.onUpdateMessage}
								onUpdateNewMessageStatus={p.onUpdateNewMessageStatus}
								onCreateErrandNote={p.onCreateErrandNote}
								onCopyErrandMessage={p.onCopyErrandMessage}
								errandInputs={p.errandInputs}
								channelId={mediaType}
								channelIcon={channelIcon}
						/>);
						if(active){
							uIHeaderBar = <ICConversationHeader
											active={active}
											status={title.status}
											label={title.hoverText}
											agents={title.text}
											ontoggleClosePanel={this.toggleClosePanel}
											onCloseChat={this.closeSession}
											currentPage={this.state.activeTabIndex}
											totalSession={numOfSessions}
											chatSessions={p.chatSessions}
											chatSessionList={p.chatSessionList}
											onActivateSession={this.handleActivateSession}
											sessionId={this.state.activeTab.sessionId}
											maximizeChatSession={this.state.maximizeChatSession}
											channel={this.getChannel(mediaType)}
											serviceIcon={serviceIcon}
										/>
						}
					}
				}.bind(this));
			}
		}
		return (
			<div className={internalChatClass}>
				{this.renderHeader()}
				<div className="internal-chat-content">
					<div className="search_field wrapper">
						<SearchFilter
							name={"search_chat"}
							label={I("Search")}
							data={p.agentList}
							fields={{id: "id", value: "userName"}}
							onSelectFilter={this.handleFilteredList} />
					</div>
					<ul className={internalChatUsersClass} data-custom-scrollbar>
						<li className="conversations">
							<a data-qa-id="ic_expand_conv" href="#" className={p.ui.showConversations ? "dropped" : ""} onClick={this.toggleShowConversation} data-dropdown-action>{I("Conversations")}</a>
							<ul className="internal-chat-users-list">
								{conversations}
							</ul>
						</li>
						<li className="favourites">
							<a data-qa-id="ic_expand_fav" href="#" className={p.ui.showFavourites ? "dropped" : ""} onClick={this.toggleShowFavourite} data-dropdown-action>{I("Favourites")}</a>
							<ul className="internal-chat-users-list">
								{favourites}
							</ul>
						</li>
						<li className="show-all">
							<a data-qa-id="ic-expand_all" href="#" className={p.ui.showAll ? "dropped" : ""} onClick={this.toggleShowAll} data-dropdown-action>{I("Show all")}</a>
							<ul className="internal-chat-users-list">
								{agents}
							</ul>
						</li>
					</ul>
					<div className="internal-chat-conversation-container">
						{uIHeaderBar}
						{tabs.length > 0 ? tabs : <div className="internal-chat-conversation"></div>}
					</div>
				</div>
			</div>
		)
	}
}
export default InternalChat;
